








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { BadgeStores } from '@/store/badges';
import AwardUserBadgeForm from '@/components/badges/AwardUserBadgeForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    AwardUserBadgeForm,
  },
})
export default class AwardUserBadges extends Vue {
  badgeStore = BadgeStores;

  unsubscribe: () => void = () => {};

  handleSubmit(payload: any) {
    this.badgeStore.awardUserBadge(payload);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'badge/AWARD_USER_BADGE_SUCCESS') {
        this.$router.replace('/badges');
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
