var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{class:["columns"]},[_c('div',{class:["column"]},[_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{ref:"badge",attrs:{"name":"Badge"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Badge"}},[_c('Dropdown',{attrs:{"data":_vm.badgelist,"placeholder":'Select a badge',"selectedText":_vm.selectedBadge,"previousSelectedValue":_vm.previousSelectedBadge},on:{"select":_vm.onBadgeSelect,"updateState":_vm.updateBadgeState}})],1),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{class:["columns"]},[_c('div',{class:["column"]},[_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{ref:"user",attrs:{"name":"Students","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Students"}},[_c('Multiselect',{attrs:{"options":_vm.userData,"searchable":true,"close-on-select":true,"clear-on-select":false,"multiple":true,"placeholder":"Type Student Email to search","label":"text","track-by":"value"},on:{"select":_vm.onUserSelect,"search-change":_vm.getUserDropdown},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("No student found. Consider changing the search keyword.")])])],1),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{class:_vm.$style.buttonContainer},[_c('input',{attrs:{"type":"submit","disabled":_vm.formData.id === 0 || _vm.formData.userid === 0},domProps:{"value":'Add'}})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }