
























































import { Vue, Component, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import Upload from '@/components/common/Upload.vue';
import { AwardUserBadgeFormPayload } from '@/models/badges';
import { ValidationObserver } from 'vee-validate';
import { DropdownItem } from '@/models/dropdown';
import { BadgeStores } from '@/store/badges';
import { UserStores } from '@/store/users';
import Multiselect from 'vue-multiselect'


@Component({
  components: {
    Dropdown,
    Upload,
    ValidationObserver,
    Multiselect,
  },
})
export default class AwardUserBadgeForm extends Vue {
  badgeStore = BadgeStores;
  userListStore = UserStores.list;
  badgeData: DropdownItem[] = [];
  userData: DropdownItem[] = [];
  selectedBadge = '- Select -';
  previousSelectedBadge = '';
  selectedUser: any[] = [];


  formData: AwardUserBadgeFormPayload = {
    id: 0,
    userids: [],
  };

  get badgelist() {
    return this.badgeData;
  }

  fetchBadges() {
    this.badgeStore.getBadges({
      page: 1,
      limit: 100,
    });
  }

  onBadgeSelect(item: DropdownItem) {
    this.updateBadgeState(item);
  }

  async onUserSelect(item: DropdownItem) {
    this.updateUserState(item);
  }

  updateBadgeState(item: DropdownItem) {
    this.selectedBadge = item.text;
    this.formData.id = parseInt(item.value);
  }

  updateUserState(item: DropdownItem) {
    this.formData.userids.push(parseInt(item.value));
  }
  
  mounted() {
    this.fetchBadges();
  }

  onSubmit() {
    const { ...formData } = this.formData;

    this.$emit('submit', {
      ...formData,
    });
  }

  getUserDropdown(keyword: string) {
    if(keyword.length > 3) {
      this.userListStore.retrieveUsers({
        page: 1,
        limit: 20,
        keyword: keyword,
        filter: 'student',
      });
    }
  }

  @Watch(`badgeStore.badges`)
  updateBadgeDropdown() {
    if(this.badgeStore.badges.data) {
      this.badgeData = [];
      this.badgeStore?.badges.data.forEach((badge) => {
        this.badgeData.push({
          text:  badge.type  + " - " + badge.name  + " ( " + badge.description + " )",
          value: badge.id.toString(),
          uniqueKey: `${badge.id}_`,
        });
      });
    }
  }

  @Watch(`userListStore.response`)
  setUsers() {
    if (this.userListStore.response) {
      this.userData = [];
      this.userListStore.response.objects.forEach((item) => {
        this.userData.push({
          text: item.name  + " / " + item.email,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });
    }
  }
}
